<template>
  <div class="manufacturing">

    <light-box :images="images" :index="index" @close="index = null" />

    <div class="page-title">
      <div class="content-container">
        <h1 class="title is-4">
          Manufacturing
        </h1>
      </div>
    </div>

    <div class="content-container page-text">
      <div class="columns">
        <div class="column">
          <p class="pad-bottom-20">
            All of our manufacturing is undertaken in our Albury based workshop with the aid of the latest technology CNC controlled cutting, folding and forming machines.
          </p>

          <p class="pad-bottom-20">
            Our workshop capabilities include:
          </p>

          <div class="content">
            <ul>
              <li>Stainless Steel Manufacturing</li>
              <li>Stainless Steel Bench Tops and Sinks</li>
              <li>Process Piping</li>
              <li>Stainless Steel Conduiting</li>
              <li>Stainless Steel Handrails and Balustrading</li>
              <li>Sheet Metal Design and Manufacturing</li>
              <li>Platforms, Walkways and Stairs</li>
              <li>Conveyor fabrication and installation</li>
              <li>Stainless Steel Tanks, Vats, Hoppers and Chutes</li>
              <li>Carbon Steel and Aluminium Fabrication</li>
              <li>Coded Pressure Welding</li>
              <li>CNC Sheet and Plate Folding</li>
              <li>Guillotine Cutting</li>
              <li>Plate and Sheet Rolling</li>
              <li>Water Jet Cutting</li>
              <li>Sectional Rolling</li>
            </ul>
          </div>
        </div>
        <div class="column">
          <div class="pad shadow">
            <b-carousel
              :autoplay="true"
              :indicator="false"
              :pause-hover="false"
              :arrow="true"
              icon-pack="far"
              icon-next="chevron-right"
              :arrow-hover="false">
              <b-carousel-item v-for="(image, i) in images" :key="i">
                <a class="image is-4by5" @click="index = i">
                  <img class="bg-fff" :src="image">
                </a>
              </b-carousel-item>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      index: null,
      images: [
        require('@/assets/img/manufacturing/1.jpg'),
        require('@/assets/img/manufacturing/2.png'),
        require('@/assets/img/manufacturing/3.png'),
        require('@/assets/img/manufacturing/4.jpg'),
        require('@/assets/img/manufacturing/5.jpg'),
        require('@/assets/img/manufacturing/6.jpg'),
        require('@/assets/img/manufacturing/7.jpg'),
        require('@/assets/img/manufacturing/8.jpg'),
        require('@/assets/img/manufacturing/9.jpg')
      ]
    }
  }
}
</script>
